export function convertDate(dateString) {
    // Define a mapping of month names to their numeric equivalents
    const monthMapping = {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
    };

    const [day, monthName, year] = dateString?.split(" ");

    // Get the numeric month using the mapping
    const numericMonth = monthMapping[monthName.trim()];

    // Return the formatted date
    return `${day?.slice(0, 2)}/${numericMonth}/${year}`;
}


export function convertDateToISO(dateString) {
    // Convert "22/11/2023" to "2023-11-22"
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
};

export function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear(); // Get the full year
    return `${day}/${month}/${year}`;
}

export function convertDatetoDbDate(duration) {
    let dy = formatDateToDDMMYYYY(duration);
    let month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    // console.log("duration", jobDescription.duration)
    const d = dy?.split("/");
    // console.log("d", d);
    let mm = Number(d?.[1]);
    // console.log("MM", mm);
    let date = `${d?.[0]}, ${month?.[mm - 1]} ${d?.[2]}`;

    return date
}