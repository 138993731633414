import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { updateJob } from "../../../Redux/ActionCreator";
import { baseURL1 } from "../../../Redux/baseURL";
import { useDispatch } from "react-redux";
import { convertDate, convertDateToISO, formatDateToDDMMYYYY, convertDatetoDbDate } from "../../../utils/format_job_date";

const DeadlineNotification = ({ jobsToShow, getUpdatedJobs, setDeadlineModalNotif, deadlineModalNotif }) => {
  const dispatch = useDispatch();
  // Filter and initialize jobs expiring in less than 7 days
  const [selectedValue, setSelectedValue] = useState("7 days");
  const [filteredJobs, setFilteredJobs] = useState(
    jobsToShow?.filter((job) => {
      if (job?.applicationDeadline) {
        const today = new Date();
        const deadline = new Date(job.applicationDeadline);
        const diffInDays = (deadline - today) / (1000 * 60 * 60 * 24); // Difference in days
        return diffInDays <= 7 && diffInDays >= 0; // Expiring within the next 7 days
      }
      return false;
    }) || []
  );

  const daysToAdd = parseInt(selectedValue.split(" ")[0]); // Extract the number of days
  let applicationDeadline = null;
  let currentDeadline = null;
  let updatedDeadline = null;

  if (filteredJobs.length > 0) {
    applicationDeadline = convertDate(filteredJobs.slice(-1)[0]?.applicationDeadline);
    currentDeadline = new Date(convertDateToISO(applicationDeadline));
    updatedDeadline = new Date(currentDeadline);
    updatedDeadline.setDate(currentDeadline.getDate() + daysToAdd);
  }

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  // Handle exclusion of a job by its jobId
  const handleExcludeJob = (custom_name) => {
    setFilteredJobs((prev) => prev.filter((job) => job.custom_name !== custom_name));
  };

  const handleExtend = (e) => {
    e.stopPropagation(); // Prevent navigation from being triggered
    setDeadlineModalNotif(true);
  }

  function handleUpdateJob(customName, updatedDeadline) {
    const dbFormattedDeadline = convertDatetoDbDate(updatedDeadline);

    dispatch(updateJob(customName, { applicationDeadline: dbFormattedDeadline }))
      .then(() => {
        // Show a success toast message
        toast.success("Job deadline updated successfully!");
        getUpdatedJobs();
        window.location.reload();
      })
      .catch((error) => {
        // Show an error toast message
        console.error("Error updating job deadline:", error);
        toast.error("Failed to update the job deadline. Please try again.");
      });
  }

  return (
    <>
      {filteredJobs.length > 0 ? (
        filteredJobs.slice(-1).map((job, index) => (
          <div
            key={index}
            className="py-2 rounded-tl-md rounded-tr-md px-4 flex justify-between items-center bg-[#F9D3C6]"
          >
            <div className="flex gap-x-2 items-center">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="24" cy="24" r="24" fill="#FF6633" fillOpacity="0.25" />
                <circle cx="24" cy="24" r="18" fill="#FF6633" />
                <path
                  d="M21.75 13.5C21.5511 13.5 21.3603 13.579 21.2197 13.7197C21.079 13.8603 21 14.0511 21 14.25C21 14.4489 21.079 14.6397 21.2197 14.7803C21.3603 14.921 21.5511 15 21.75 15H26.25C26.4489 15 26.6397 14.921 26.7803 14.7803C26.921 14.6397 27 14.4489 27 14.25C27 14.0511 26.921 13.8603 26.7803 13.7197C26.6397 13.579 26.4489 13.5 26.25 13.5H21.75ZM24 20.25C24.1989 20.25 24.3897 20.329 24.5303 20.4697C24.671 20.6103 24.75 20.8011 24.75 21V25.5C24.75 25.6989 24.671 25.8897 24.5303 26.0303C24.3897 26.171 24.1989 26.25 24 26.25C23.8011 26.25 23.6103 26.171 23.4697 26.0303C23.329 25.8897 23.25 25.6989 23.25 25.5V21C23.25 20.8011 23.329 20.6103 23.4697 20.4697C23.6103 20.329 23.8011 20.25 24 20.25ZM33 25.5C33 30.4702 28.9702 34.5 24 34.5C19.0297 34.5 15 30.4702 15 25.5C15 20.5297 19.0297 16.5 24 16.5C28.9702 16.5 33 20.5297 33 25.5ZM31.5 25.5C31.5 21.3577 28.1422 18 24 18C19.8577 18 16.5 21.3577 16.5 25.5C16.5 29.6423 19.8577 33 24 33C28.1422 33 31.5 29.6423 31.5 25.5ZM32.031 16.7198C31.9618 16.6481 31.8791 16.591 31.7876 16.5517C31.696 16.5124 31.5976 16.4917 31.498 16.4908C31.3985 16.4899 31.2997 16.5089 31.2075 16.5466C31.1154 16.5843 31.0316 16.64 30.9612 16.7105C30.8908 16.7809 30.8351 16.8646 30.7974 16.9568C30.7597 17.049 30.7407 17.1477 30.7416 17.2473C30.7424 17.3469 30.7631 17.4453 30.8024 17.5368C30.8417 17.6283 30.8989 17.7111 30.9705 17.7802L32.4705 19.2802C32.5402 19.3499 32.6229 19.4051 32.7139 19.4428C32.8049 19.4804 32.9025 19.4998 33.001 19.4998C33.0995 19.4997 33.1971 19.4803 33.2881 19.4426C33.3791 19.4048 33.4617 19.3496 33.5314 19.2799C33.601 19.2102 33.6562 19.1275 33.6939 19.0365C33.7316 18.9454 33.7509 18.8479 33.7509 18.7494C33.7509 18.6508 33.7314 18.5533 33.6937 18.4623C33.656 18.3713 33.6007 18.2886 33.531 18.219L32.031 16.7198Z"
                  fill="white"
                />
              </svg>
              <p>
                <span className="font-semibold">{job.jobTitle}</span> job is
                about to expire.{" "}
                <span onClick={handleExtend} className="cursor-pointer italic font-medium underline">
                  Click here
                </span>{" "}
                to extend the deadline for FREE.
              </p>
            </div>

            <div
              className="bg-white w-max p-[7px] rounded-full cursor-pointer"
              onClick={() => handleExcludeJob(job.custom_name)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612"
                  stroke="black"
                  strokeWidth="1.02128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        ))
      ) : (
        null
      )}

      {deadlineModalNotif && <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 backdrop-blur-[2px] flex justify-center items-center">
        <section className="bg-white w-full rounded-xl max-w-[600px] font-main">
          <div className="border-b-8 flex rounded-tl-xl rounded-tr-xl justify-between items-center bg-[#F9D3C6] border-[#E9F1FF] px-6 py-4">
            <div onClick={handleExtend} className="font-semibold text-base gap-x-2 py-2 px-4 flex ">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.4805" cy="12" r="12" fill="#FF6633" fill-opacity="0.25" />
                <circle cx="12.4805" cy="12" r="9" fill="#FF6633" />
                <path d="M11.3555 6.75C11.256 6.75 11.1606 6.78951 11.0903 6.85983C11.02 6.93016 10.9805 7.02554 10.9805 7.125C10.9805 7.22446 11.02 7.31984 11.0903 7.39017C11.1606 7.46049 11.256 7.5 11.3555 7.5H13.6055C13.7049 7.5 13.8003 7.46049 13.8706 7.39017C13.941 7.31984 13.9805 7.22446 13.9805 7.125C13.9805 7.02554 13.941 6.93016 13.8706 6.85983C13.8003 6.78951 13.7049 6.75 13.6055 6.75H11.3555ZM12.4805 10.125C12.5799 10.125 12.6753 10.1645 12.7456 10.2348C12.816 10.3052 12.8555 10.4005 12.8555 10.5V12.75C12.8555 12.8495 12.816 12.9448 12.7456 13.0152C12.6753 13.0855 12.5799 13.125 12.4805 13.125C12.381 13.125 12.2856 13.0855 12.2153 13.0152C12.145 12.9448 12.1055 12.8495 12.1055 12.75V10.5C12.1055 10.4005 12.145 10.3052 12.2153 10.2348C12.2856 10.1645 12.381 10.125 12.4805 10.125ZM16.9805 12.75C16.9805 15.2351 14.9656 17.25 12.4805 17.25C9.99534 17.25 7.98047 15.2351 7.98047 12.75C7.98047 10.2649 9.99534 8.25 12.4805 8.25C14.9656 8.25 16.9805 10.2649 16.9805 12.75ZM16.2305 12.75C16.2305 10.6789 14.5516 9 12.4805 9C10.4093 9 8.73047 10.6789 8.73047 12.75C8.73047 14.8211 10.4093 16.5 12.4805 16.5C14.5516 16.5 16.2305 14.8211 16.2305 12.75ZM16.496 8.35988C16.4614 8.32406 16.42 8.29549 16.3742 8.27584C16.3285 8.25618 16.2793 8.24584 16.2295 8.24541C16.1797 8.24497 16.1303 8.25446 16.0842 8.27332C16.0381 8.29217 15.9963 8.32002 15.9611 8.35523C15.9259 8.39044 15.898 8.43231 15.8792 8.47839C15.8603 8.52448 15.8508 8.57386 15.8512 8.62365C15.8517 8.67344 15.862 8.72265 15.8817 8.7684C15.9013 8.81415 15.9299 8.85553 15.9657 8.89012L16.7157 9.64012C16.7506 9.67494 16.7919 9.70256 16.8374 9.72139C16.8829 9.74022 16.9317 9.74991 16.981 9.74989C17.0302 9.74987 17.079 9.74015 17.1245 9.72129C17.17 9.70242 17.2113 9.67478 17.2462 9.63994C17.281 9.6051 17.3086 9.56374 17.3274 9.51823C17.3463 9.47271 17.3559 9.42394 17.3559 9.37468C17.3559 9.32542 17.3462 9.27665 17.3273 9.23115C17.3085 9.18565 17.2808 9.14432 17.246 9.1095L16.496 8.35988Z" fill="white" />
              </svg>
              Extend Deadline
            </div>
            <div
              className="bg-white w-max p-[7px] rounded-full cursor-pointer"
              onClick={() => setDeadlineModalNotif(false)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612"
                  stroke="black"
                  strokeWidth="1.02128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>


          <div className="p-6 flex flex-col gap-y-4">
            <div className="bg-[#777DEA1A] border-[#777DEA54] border rounded-lg px-8 py-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#00000080]">Job Title</p>
                  <p className="text-xl text-black font-500">{filteredJobs.slice(-1)[0].jobTitle}</p>
                </div>

                <div>
                  <p className="text-[#00000080]">Current Deadline</p>
                  <p className="text-xl text-black font-500">{applicationDeadline}</p>
                </div>
              </div>
            </div>

            <div className="bg-[#FF6633] rounded-sm py-2 px-4">
              <p className="font-semibold leading-[1.4] text-white">Select new deadline</p>
            </div>

            <div className="flex bg-[#777DEA1A] border-[#777DEA54] border py-4 px-6 rounded-lg flex-col gap-y-4">
              <p className="font-500 text-sm leading-normal">How many days do you want to add to the deadline?</p>

              <div className="flex flex-col md:flex-row gap-3 items-center">
                {
                  ["7 days", "14 days", "21 days"].map((item, i) => {
                    return (
                      <label
                        htmlFor={item.split(" ").join("_")}
                        className={`flex items-center w-full px-4 py-2 rounded-md border ${selectedValue === item ? "border-[#FF6633]" : "border-[#898989]"
                          } cursor-pointer`}
                      >
                        <input
                          type="radio"
                          id={item.split(" ").join("_")}
                          name={"new_deadline"}
                          value={item}
                          checked={selectedValue === item}
                          onChange={handleRadioChange}
                          className="w-6 h-6 text-[#FF6633] accent-[#FF6633] cursor-pointer"
                        />
                        <span className="ml-2 text-sm w-full font-medium text-gray-800">
                          {item}
                        </span>
                      </label>
                    )
                  })
                }
              </div>

              <div className="p-4 bg-[#F9D3C6] text-[#22263A] font-500 rounded-lg w-max">Your new deadline will be: <span className="text-xl">{`   ${formatDateToDDMMYYYY(updatedDeadline) || applicationDeadline}`}</span></div>
            </div>

            <div onClick={() => handleUpdateJob(filteredJobs?.slice(-1)[0]?.custom_name, updatedDeadline)} className="cursor-pointer bg-[#FF6633] px-9 py-3 rounded-lg text-white w-max">Save New Deadline</div>
          </div>

        </section></div>}
      <ToastContainer />
    </>
  );
};

export default DeadlineNotification;
