import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PurpleTransBtn from "../common/purple_transparent_btn";
import SkipBtn from "../common/skills_btn";
import BackButton from "../common/back_button";
// import { useSelector } from "react-redux";
import reference from "../common/reference";
import LoaderModal from "../common/LoaderModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getProfile,
  cv_loading,
  getAllMyCv,
  employeePublicView,
  getting_successful,
  establishChat,

} from "../../Redux/ActionCreator";
import { useAnalytics } from "../../context/AnalyticsContext";
import DashboardOptionLink from "../common/dashboard_options_link";
import JobPostTableItem from "../common/job_posts_item/job_posts_table_item";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import socketClient from "../../socket.io";

function ApplicationDetails() {
  // worker profile
  const analytics = useAnalytics();
  const savedResume = useSelector((e) => e.saved_cv);
  const res = useSelector((e) => e.last_resume);
  const details = savedResume.message;
  const profile = useSelector((prev) => prev.public);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = window.location.pathname.split("/");
  // employer profile
  const employer = useSelector((e) => e.employer);
  const employerprofile = employer.message;
  const params = useParams().profileId;
  const dataIndex = useParams().applicationDataIndexNum;
  // getting job information from the redux store
  const jobsPostedState = useSelector(store => store.jobs_posted);
  const jobName = useParams().jobName;
  const job = jobsPostedState.message?.filter(item => item.custom_name === jobName)?.[0];
  const socketClient = useSelector(e => e.socket?.message);
  const sidebar = useSelector(e => e.sidebarOpen.message);

  const applicants = useSelector(e => e.applicants.message);
  const answers = applicants?.applications[dataIndex]?.answers;
  // console.log(applicants);

  // console.log("This is employer profile!!!", employerprofile);
  // console.log("params", params);

  useEffect(() => {
    if (applicants?.applications[dataIndex] !== undefined) {
      dispatch(getting_successful([applicants?.applications[dataIndex]?.resumeId]));
    }
    // const token = localStorage.getItem('token');
    // dispatch(postUserProfile(token));
    // console.log("Called!!!!!", profile.message);
    dispatch(employeePublicView(params));
  }, []);

  // console.log("this is your profile", profile);

  const handleResumePreview = () => {
    navigate(
      `/resume/${applicants?.applications[dataIndex]?.resumeId._id}?id=${applicants?.applications[dataIndex]?.resumeId?.pdfId}&payment_intent_client_secret=${applicants?.applications[dataIndex]?.resumeId?.Secret}&stat=cannot update&jobName=${jobName}&pr=${params}&index=${dataIndex}`
    );
  }

  const handlePost = () => {
    if (profile !== null) {
      analytics.track("Post job btn click", {
        Type: "Employer",
        time: new Date(),
        Email: profile?.email,
        Company_name: profile?.name,
        profileId: profile?._id,
        userId: profile?.userId
      });
    }
    navigate("/employer/upload");
  }

  const getDate = (date) => {
    // console.log("date", date);
    if (date !== null && date !== undefined) {
      let split_date = date.split("T")[0].split("-");
      const return_date =
        split_date[2] +
        "/" +
        split_date[1] +
        "/" +
        split_date[0][2] +
        split_date[0][3];
      return return_date;
    }
  };

  const handleEstablishMessage = () => {
    // console.log("dataIndex", dataIndex);
    // emit an event here first
    let notification = {
      notificationType: "Interest",
      notificationMessage: "An employer is interested in your application.",
    }
    socketClient.emit("interestNotification", { employerId: employer.message, employeeId: profile.message?.profile, notification: notification });
    dispatch(establishChat(employer.message?._id, profile.message?.profile?._id, applicants?.jobInfo?.jobTitle, applicants?.applications[dataIndex]?.resumeId?._id, applicants?.jobInfo?._id)); // function to establish chat;
    navigate("/employer/inbox")
  }

  const handleViewApplicantTest = () => {
    navigate("testpreview");
  }

  return (
    <>
      {/* { profile.message !== null ? ( */}
      <div className="h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className="w-full flex flex-col gap-y-6  lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
          <div className="flex flex-col gap-y-4  lg:flex-row lg:items-center justify-between">
            <div className="flex flex-col gap-y-1">
              {/* back buttton  */}
              <BackButton onClick={() => navigate(`/employer/${url[2]}/${url[3]}`)} />
              <h1 className="font-semibold text-[20px] md:text-[24px] leading-normal text-black">
                Application Details
              </h1>
              <p className="text-base md:text-[20px] leading-normal text-black">This candidate has shown interest in your job post. </p>
            </div>

            <DashboardOptionLink
              header="Post a Job"
              description={`Use our AI technology to create a job post in quick, easy steps.`}
              ellipseStyle="bg-white left-[60%] top-[10%]  bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
              bgBack="dash-link-grad"
              svgType="postJob"
              onClick={handlePost}
              leftBordStyle="bg-[#FF6633]"
            />
          </div>
          <JobPostTableItem
            logo={employerprofile?.photo}
            postAge={job?.createdAt}
            onClick={() => navigate(`/employer/create-job/preview?jN=${job?.custom_name}&pw=true`)}
            jobPosition={job?.jobTitle}
            jobName={jobName} // select the new job id here
            closed={applicants?.jobInfo.availability}
          />

          <section className="w-full  flex flex-col gap-6">
            <h1 className=" font-semibold text-xl ipad:text-2xl text-[#5843BD]">
              Candidate’s Information
            </h1>

            <div className="bg-[#5843BD] p-4 xl:p-6 flex-col gap-y-6 lg:flex-row rounded-xl flex justify-between">
              <div className="w-full max-w-[500px] flex-col md:flex-row gap-y-4 flex gap-x-2 xl:gap-x-4">
                <div className="self-center md:self-start">
                  {profile.message?.profile?.photo !== undefined ? (
                    <img
                      src={profile.message?.profile?.photo}
                      alt=""
                      className="min-w-[120px]  border-4 border-solid border-white h-[120px] rounded-2xl object-cover"
                    />
                  ) : (
                    <img
                      src={reference.profilephoto1}
                      alt=""
                      className="object-cover"
                    />
                  )}
                </div>
                <div className="w-full md:px-4 gap-y-2 flex flex-col justify-between">
                  <div>
                    <h2 className="font-semibold capitalize text-[20px] text-[#FEFEFE] cursor-pointer notranslate" onClick={() => navigate(`/profile/beta/${params}?d=vp&jobName=${jobName}&pr=${params}&index=${dataIndex}`)}>
                      {profile?.message?.profile?.Fullname?.toLowerCase()}
                    </h2>
                    <p className="font-normal text-base capitalize  text-[#FEFEFE]">
                      {profile?.message?.profile?.Job_type?.toLowerCase()}
                    </p>
                    {/* rating should be here */}
                  </div>

                  <div className="flex flex-col gap-y-4 md:flex-row md:items-center justify-between">
                    <div>
                      <p className="text-[#FEFEFE80] text-base leadning-normal font-medium">Location</p>
                      <p className="text-[#FEFEFE] text-base leadning-normal font-medium">{profile?.message?.profile?.Address}</p>
                    </div>

                    <div>
                      <p className="text-[#FEFEFE80] text-base leadning-normal font-medium">Work Experience</p>
                      <p className="text-[#FEFEFE] text-base leadning-normal font-medium">{profile?.message?.profile?.Years_of_experience}+ years Experience</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-y-4 md:flex-row lg:flex-col w-full lg:w-max justify-between">
                <div className="bg-[#FF6633] cursor-pointer flex justify-center whitespace-nowrap rounded-[8px] text-white leading-normal font-semibold px-5 py-3 " onClick={handleEstablishMessage}>Interview Candidate</div>
                <div className="border rounded-[8px] cursor-pointer flex justify-center whitespace-nowrap text-white leading-normal font-semibold px-5 py-3 border-white" onClick={() => navigate(`/profile/beta/${params}?d=vp&jobName=${jobName}&pr=${params}&index=${dataIndex}`)}>View full profile</div>
              </div>
            </div>

            {/* <div className="px-6">
                <h3 className=" font-semibold text-base text-[#5843BD]">
                  Technical Skills
                </h3>
                <div>
                  {profile?.message?.profile?.Skills?.map((things, index) => (
                    <Skillsbtn btnid={index} btntxt={things?.label} />
                  ))}
                </div>
              </div> */}
          </section>

          <section className="p-4 xl:p-6 w-full flex flex-col bg-white rounded-[16px] gap-6">
            <h1 className="font-semibold text-2xl text-[#5843BD]">
              Application Details
            </h1>

            <div className={`${sidebar ? 'flex-col gap-y-4 xl:flex-row xl:items-center' : 'flex-col lg:flex-row gap-y-4 flex-row lg:items-center'} flex bg-[#EFECFF] rounded-xl px-6 py-4  justify-between w-full`}>
              <div className="flex flex-col">
                <h4 className=" font-semibold text-[20px] leading-[1.4] text-[#5843BD]">
                  Preview Resume
                </h4>
                <p className="font-normal text-base leading-[1.3] mb-2 text-[#1F1F1F]">
                  Click on the document to view its content.
                </p>
                <a href={`${applicants?.applications[dataIndex]?.resumeId?.aws_link}`} download={`${applicants?.applications[dataIndex]?.resumeId?.Fullname}`} className="px-2 py-2 rounded-lg bg-[#FEFEFE] flex gap-4 cursor-pointer" /* onClick={handleResumePreview} */>
                  <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_10745_9716)">
                      <path d="M18.0847 11.6856H28V35.5851C28.0008 36.0494 27.8168 36.4951 27.4886 36.8233C27.1604 37.1518 26.7147 37.3358 26.2504 37.3347H1.74961C1.28529 37.3358 0.839611 37.1518 0.51141 36.8233C0.183188 36.4951 -0.000807784 36.0494 2.67473e-06 35.5851V1.75158C-0.0008091 1.28726 0.183188 0.841579 0.51141 0.513378C0.839632 0.184886 1.28529 0.000891128 1.74961 0.00197174H16.3275V9.92074C16.3242 10.3886 16.5082 10.838 16.8381 11.1695C17.1682 11.5009 17.6168 11.6868 18.0847 11.6857L18.0847 11.6856ZM20.1252 16.3405H7.87479C7.40127 16.351 7.02166 16.7358 7.01758 17.2096V17.7913C7.01758 18.2681 7.39829 18.6575 7.87479 18.668H20.1252C20.6001 18.6575 20.9803 18.2705 20.9824 17.7951V17.2133C20.9802 16.7382 20.6001 16.351 20.1252 16.3404V16.3405ZM20.9824 21.8761C20.9846 21.398 20.603 21.0061 20.1252 20.9956H7.87479C7.39694 21.0062 7.01543 21.398 7.01758 21.8761V22.4579C7.0238 22.93 7.40289 23.3126 7.87479 23.3229H20.1252C20.5971 23.3126 20.9762 22.93 20.9824 22.4579V21.8761ZM20.9824 26.531C20.9846 26.0528 20.603 25.661 20.1252 25.6505H7.87479C7.39234 25.661 7.00892 26.0599 7.01758 26.5426V27.1244C7.01758 27.6087 7.4102 28.001 7.89426 28.001H20.1253C20.6018 27.9905 20.9825 27.6011 20.9825 27.1244L20.9824 26.531ZM27.9648 9.32362H18.6549V0.00195787H19.1011C19.5665 0.000875523 20.0133 0.185142 20.3423 0.514175L27.4799 7.65937L27.4801 7.6591C27.8076 7.98624 27.9916 8.43001 27.9921 8.89273L27.9648 9.32362Z" fill="#F4AE7F" />
                    </g>
                    <defs>
                      <clipPath id="clip0_10745_9716">
                        <rect width="28" height="37.3328" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <div>
                    <p className="font-semibold capitalize text-[18px] text-start text-black notranslate">
                      {profile?.message?.profile?.Fullname?.toLowerCase()}
                    </p>
                    <p className="text-[#1C1C1C] text-opacity-60 text-base text-start leading-[1.1] font-normal">
                      Resume created on Moil.
                    </p>
                  </div>
                </a>
              </div>

              <div className={`py-2 px-4 rounded-[8px] bg-white ${sidebar ? 'hidden xl:flex' : 'flex hidden lg:flex'} flex-col gap-y-4`}>
                <p className="text-[#131313] leading-normal text-base font-medium">Date Applied</p>
                <p className="text-[#131313] leading-normal text-[32px] font-medium">{getDate(applicants?.applications[dataIndex]?.createdAt)}</p>
              </div>

              <div className={`py-2 px-4 rounded-[8px] bg-white ${sidebar ? 'hidden xl:flex' : 'flex hidden lg:flex'} flex flex-col gap-y-4`}>
                <div>
                  <p className="text-[#131313] leading-normal text-base font-medium">Test Score</p>
                  <p className="text-[#131313] leading-normal text-[24px] font-medium">{applicants?.applications[dataIndex]?.testScore}%</p>
                </div>
                {(applicants?.applications[dataIndex]?.testScore > 0 && answers) && <div onClick={handleViewApplicantTest} className="bg-[#FF6633] flex justify-center cursor-pointer whitespace-nowrap rounded-[8px] text-white leading-normal font-semibold px-5 py-3 ">Check Test Details</div>}
              </div>

              <div className={`${sidebar ? 'flex flex-col md:flex-row justify-between' : ' flex-col md:flex-row flex lg:hidden'} xl:hidden flex gap-y-4 justify-between`}>
                <div className="py-2 px-4 rounded-[8px] bg-white flex flex-col gap-y-4">
                  <p className="text-[#131313] leading-normal text-base font-medium">Date Applied</p>
                  <p className="text-[#131313] leading-normal text-[32px] font-medium">{getDate(applicants?.applications[dataIndex]?.createdAt)}</p>
                </div>

                <div className="py-2 px-4 rounded-[8px] bg-white flex flex-col gap-y-4">
                  <div>
                    <p className="text-[#131313] leading-normal text-base font-medium">Test Score</p>
                    <p className="text-[#131313] leading-normal text-[32px] font-medium">{applicants?.applications[dataIndex]?.testScore}%</p>
                  </div>
                  {(applicants?.applications[dataIndex]?.testScore > 0 && answers) && <div onClick={handleViewApplicantTest} className="bg-[#FF6633] flex justify-center cursor-pointer whitespace-nowrap rounded-[8px] text-white leading-normal font-semibold px-5 py-3 ">Check Test Details</div>}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center p-4 bg-[#F9E9E74D] rounded-xl">
              <h2 className=" font-semibold text-base leading-[1.4] text-[#22263A]">
                Application Status
              </h2>
              <p className="rounded-[4px] py-2 px-3 bg-[#FF663326] text-sm lg:text-base font-medium leading-normal text-[#111111]">
                {applicants?.applications[dataIndex]?.stages}
              </p>
            </div>

            <p className="text-base leading-[1.3] font-medium text-[#1F1F1F]">
              To progress with this candidate, click on{" "}
              <span className="text-[#5843BD]">I’m interested</span> to start
              chatting with them.
            </p>

            <div className="flex flex-col gap-y-4 md:flex-row justify-between">
              <div className="bg-[#FF6633] flex justify-center cursor-pointer whitespace-nowrap rounded-[8px] text-white leading-normal font-semibold px-5 py-3 " onClick={handleEstablishMessage}>Interview Candidate</div>
              <div className="py-3 px-5 rounded-[8px] flex justify-center items-center bg-[#FF66331A] gap-x-1" onClick={() => navigate(-1)}>
                <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 1.33203C8.5 1.19942 8.44732 1.07225 8.35355 0.978478C8.25979 0.88471 8.13261 0.832031 8 0.832031C7.86739 0.832031 7.74021 0.88471 7.64645 0.978478C7.55268 1.07225 7.5 1.19942 7.5 1.33203V4.58003L1.233 0.944031C0.713 0.642031 0 0.987031 0 1.64003V9.02403C0 9.67703 0.713 10.022 1.233 9.72003L7.5 6.08403V9.33203C7.5 9.46464 7.55268 9.59182 7.64645 9.68559C7.74021 9.77935 7.86739 9.83203 8 9.83203C8.13261 9.83203 8.25979 9.77935 8.35355 9.68559C8.44732 9.59182 8.5 9.46464 8.5 9.33203V1.33203ZM1 1.96503L6.804 5.33203L1 8.69903V1.96503Z" fill="#F24E1E" />
                </svg>

                <span className="text-center text-base leading-normal font-medium text-[#FF6633]">Skip for now</span>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* ) : (
        <div>
          <LoaderModal text="Fetching profile" />
        </div>
      )} */}
      <ToastContainer />
    </>
  );
}

export default ApplicationDetails;
