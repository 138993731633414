import { useState } from "react";
import { createPortal } from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { baseURL1 } from "../../../Redux/baseURL";
import timeResolver from "../timeResolver";
import { useDispatch } from "react-redux";
import { updateJob } from "../../../Redux/ActionCreator";
import { convertDate, convertDateToISO, formatDateToDDMMYYYY, convertDatetoDbDate } from "../../../utils/format_job_date";


export default function JobDetailItemMobile(props) {

  const [selectedValue, setSelectedValue] = useState("7 days");
  const dispatch = useDispatch();

  const today = new Date();
  const deadline = new Date(props.applicationDeadline);
  const diffInDays = (deadline - today) / (1000 * 60 * 60 * 24); // Difference in days
  let closeToExpire = diffInDays <= 7 && diffInDays >= 0; // Expiring within the next 7 days

  const daysToAdd = parseInt(selectedValue.split(" ")[0]); // Extract the number of days
  let applicationDeadline = convertDate(props.applicationDeadline);
  const currentDeadline = new Date(convertDateToISO(applicationDeadline));

  // Use setDate to add days
  const updatedDeadline = new Date(currentDeadline);
  updatedDeadline.setDate(currentDeadline.getDate() + daysToAdd);


  const handleExtend = (e) => {
    e.stopPropagation(); // Prevent navigation from being triggered
    props.setDeadlineModalMob(true);
  }

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  function handleUpdateJob(customName, updatedDeadline) {
    const dbFormattedDeadline = convertDatetoDbDate(updatedDeadline);

    dispatch(updateJob(customName, { applicationDeadline: dbFormattedDeadline }))
      .then(() => {
        // Show a success toast message
        toast.success("Job deadline updated successfully!");
         // Navigate to the dashboard
         window.location.assign(`${baseURL1}/employer/jobs`);
      })
      .catch((error) => {
        // Show an error toast message
        console.error("Error updating job deadline:", error);
        toast.error("Failed to update the job deadline. Please try again.");
      });
  }

  const navigate = useNavigate();
  return (
    <>
      <div onClick={() => navigate(props.urlToDetails)} className="flex cursor-pointer flex-col rounded-[8px] gap-y-4 border border-[#D9D9D9] gap-y-2 bg-white px-2 py-2">
        <div className="flex items-center justify-between">
          <div className="flex gap-x-2 h-full items-center">
            <div>
              <img className="w-[45px] h-[45px] rounded-[4px]" src={props.logo} />
            </div>
            <div>
              <p class="text-base font-medium truncate leading-normal text-left text-[#5843BD]">
                {props.jobPosition}
              </p>
              <p class="text-[10px] text-[#4E5161] uppercase font-normal leading-[15px] text-left">
                Posted {`${timeResolver(props.createdAt)}`}
              </p>
            </div>
          </div>
          {(closeToExpire && props.status === "Active") && <div onClick={handleExtend} className="font-semibold w-max text-base gap-x-2 py-2 px-4 flex bg-[#F9D3C6] rounded-md">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12.4805" cy="12" r="12" fill="#FF6633" fill-opacity="0.25" />
              <circle cx="12.4805" cy="12" r="9" fill="#FF6633" />
              <path d="M11.3555 6.75C11.256 6.75 11.1606 6.78951 11.0903 6.85983C11.02 6.93016 10.9805 7.02554 10.9805 7.125C10.9805 7.22446 11.02 7.31984 11.0903 7.39017C11.1606 7.46049 11.256 7.5 11.3555 7.5H13.6055C13.7049 7.5 13.8003 7.46049 13.8706 7.39017C13.941 7.31984 13.9805 7.22446 13.9805 7.125C13.9805 7.02554 13.941 6.93016 13.8706 6.85983C13.8003 6.78951 13.7049 6.75 13.6055 6.75H11.3555ZM12.4805 10.125C12.5799 10.125 12.6753 10.1645 12.7456 10.2348C12.816 10.3052 12.8555 10.4005 12.8555 10.5V12.75C12.8555 12.8495 12.816 12.9448 12.7456 13.0152C12.6753 13.0855 12.5799 13.125 12.4805 13.125C12.381 13.125 12.2856 13.0855 12.2153 13.0152C12.145 12.9448 12.1055 12.8495 12.1055 12.75V10.5C12.1055 10.4005 12.145 10.3052 12.2153 10.2348C12.2856 10.1645 12.381 10.125 12.4805 10.125ZM16.9805 12.75C16.9805 15.2351 14.9656 17.25 12.4805 17.25C9.99534 17.25 7.98047 15.2351 7.98047 12.75C7.98047 10.2649 9.99534 8.25 12.4805 8.25C14.9656 8.25 16.9805 10.2649 16.9805 12.75ZM16.2305 12.75C16.2305 10.6789 14.5516 9 12.4805 9C10.4093 9 8.73047 10.6789 8.73047 12.75C8.73047 14.8211 10.4093 16.5 12.4805 16.5C14.5516 16.5 16.2305 14.8211 16.2305 12.75ZM16.496 8.35988C16.4614 8.32406 16.42 8.29549 16.3742 8.27584C16.3285 8.25618 16.2793 8.24584 16.2295 8.24541C16.1797 8.24497 16.1303 8.25446 16.0842 8.27332C16.0381 8.29217 15.9963 8.32002 15.9611 8.35523C15.9259 8.39044 15.898 8.43231 15.8792 8.47839C15.8603 8.52448 15.8508 8.57386 15.8512 8.62365C15.8517 8.67344 15.862 8.72265 15.8817 8.7684C15.9013 8.81415 15.9299 8.85553 15.9657 8.89012L16.7157 9.64012C16.7506 9.67494 16.7919 9.70256 16.8374 9.72139C16.8829 9.74022 16.9317 9.74991 16.981 9.74989C17.0302 9.74987 17.079 9.74015 17.1245 9.72129C17.17 9.70242 17.2113 9.67478 17.2462 9.63994C17.281 9.6051 17.3086 9.56374 17.3274 9.51823C17.3463 9.47271 17.3559 9.42394 17.3559 9.37468C17.3559 9.32542 17.3462 9.27665 17.3273 9.23115C17.3085 9.18565 17.2808 9.14432 17.246 9.1095L16.496 8.35988Z" fill="white" />
            </svg>
            <span className="hidden xl:inline">Extend</span>
          </div>}
        </div>

        <div className="flex justify-between">
          <div className="flex items-center gap-x-2 justify-between">
            {
              props.status === "Active" ? <div className="bg-[#5843BD26] w-max rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
                Active
              </div> : props.status === "Pending" ? <div className="bg-[#FF663326] w-max rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
                Pending
              </div>
                /* : props.status === "Hired" ? <div className="bg-[#5843BD80] w-max rounded-[4px] text-white py-2 px-3 text-base font-medium leading-normal text-left">
                  Hired
                </div> */
                : <div className="bg-[#FF6633] py-2 px-3 rounded-[4px] w-max text-base font-medium leading-normal text-left text-white">
                  Closed
                </div>
            }

            <p className="text-base p-2 bg-[#FF663308] flex items-center gap-x-1 rounded-[4px] leading-normal font-normal text-[#000000CC]">
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.544 6.99031C20.848 7.41631 21 7.63031 21 7.94531C21 8.26131 20.848 8.47431 20.544 8.90031C19.178 10.8163 15.689 14.9453 11 14.9453C6.31 14.9453 2.822 10.8153 1.456 8.90031C1.152 8.47431 1 8.26031 1 7.94531C1 7.62931 1.152 7.41631 1.456 6.99031C2.822 5.07431 6.311 0.945312 11 0.945312C15.69 0.945312 19.178 5.07531 20.544 6.99031Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14 7.94531C14 7.14966 13.6839 6.3866 13.1213 5.82399C12.5587 5.26138 11.7956 4.94531 11 4.94531C10.2044 4.94531 9.44129 5.26138 8.87868 5.82399C8.31607 6.3866 8 7.14966 8 7.94531C8 8.74096 8.31607 9.50402 8.87868 10.0666C9.44129 10.6292 10.2044 10.9453 11 10.9453C11.7956 10.9453 12.5587 10.6292 13.1213 10.0666C13.6839 9.50402 14 8.74096 14 7.94531Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              {props.views ? props.views : '0'}</p>
          </div>

          <div className="py-2 whitespace-nowrap text-sm lg:text-base font-medium leading-normal text-left text-[#5843BD]">
            View Details
          </div>
        </div>
      </div>

      {props.deadlineModalMob && <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 backdrop-blur-[2px] flex justify-center items-center">
        <section className="bg-white w-full rounded-xl max-w-[600px] font-main">
          <div className="border-b-8 flex rounded-tl-xl rounded-tr-xl justify-between items-center bg-[#F9D3C6] border-[#E9F1FF] px-6 py-4">
            <div onClick={handleExtend} className="font-semibold text-base gap-x-2 py-2 px-4 flex ">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.4805" cy="12" r="12" fill="#FF6633" fill-opacity="0.25" />
                <circle cx="12.4805" cy="12" r="9" fill="#FF6633" />
                <path d="M11.3555 6.75C11.256 6.75 11.1606 6.78951 11.0903 6.85983C11.02 6.93016 10.9805 7.02554 10.9805 7.125C10.9805 7.22446 11.02 7.31984 11.0903 7.39017C11.1606 7.46049 11.256 7.5 11.3555 7.5H13.6055C13.7049 7.5 13.8003 7.46049 13.8706 7.39017C13.941 7.31984 13.9805 7.22446 13.9805 7.125C13.9805 7.02554 13.941 6.93016 13.8706 6.85983C13.8003 6.78951 13.7049 6.75 13.6055 6.75H11.3555ZM12.4805 10.125C12.5799 10.125 12.6753 10.1645 12.7456 10.2348C12.816 10.3052 12.8555 10.4005 12.8555 10.5V12.75C12.8555 12.8495 12.816 12.9448 12.7456 13.0152C12.6753 13.0855 12.5799 13.125 12.4805 13.125C12.381 13.125 12.2856 13.0855 12.2153 13.0152C12.145 12.9448 12.1055 12.8495 12.1055 12.75V10.5C12.1055 10.4005 12.145 10.3052 12.2153 10.2348C12.2856 10.1645 12.381 10.125 12.4805 10.125ZM16.9805 12.75C16.9805 15.2351 14.9656 17.25 12.4805 17.25C9.99534 17.25 7.98047 15.2351 7.98047 12.75C7.98047 10.2649 9.99534 8.25 12.4805 8.25C14.9656 8.25 16.9805 10.2649 16.9805 12.75ZM16.2305 12.75C16.2305 10.6789 14.5516 9 12.4805 9C10.4093 9 8.73047 10.6789 8.73047 12.75C8.73047 14.8211 10.4093 16.5 12.4805 16.5C14.5516 16.5 16.2305 14.8211 16.2305 12.75ZM16.496 8.35988C16.4614 8.32406 16.42 8.29549 16.3742 8.27584C16.3285 8.25618 16.2793 8.24584 16.2295 8.24541C16.1797 8.24497 16.1303 8.25446 16.0842 8.27332C16.0381 8.29217 15.9963 8.32002 15.9611 8.35523C15.9259 8.39044 15.898 8.43231 15.8792 8.47839C15.8603 8.52448 15.8508 8.57386 15.8512 8.62365C15.8517 8.67344 15.862 8.72265 15.8817 8.7684C15.9013 8.81415 15.9299 8.85553 15.9657 8.89012L16.7157 9.64012C16.7506 9.67494 16.7919 9.70256 16.8374 9.72139C16.8829 9.74022 16.9317 9.74991 16.981 9.74989C17.0302 9.74987 17.079 9.74015 17.1245 9.72129C17.17 9.70242 17.2113 9.67478 17.2462 9.63994C17.281 9.6051 17.3086 9.56374 17.3274 9.51823C17.3463 9.47271 17.3559 9.42394 17.3559 9.37468C17.3559 9.32542 17.3462 9.27665 17.3273 9.23115C17.3085 9.18565 17.2808 9.14432 17.246 9.1095L16.496 8.35988Z" fill="white" />
              </svg>
              Extend Deadline
            </div>
            <div
              className="bg-white w-max p-[7px] rounded-full cursor-pointer"
              onClick={() => props.setDeadlineModalMob(false)}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612"
                  stroke="black"
                  strokeWidth="1.02128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>


          <div className="p-6 flex flex-col gap-y-4">
            <div className="bg-[#777DEA1A] border-[#777DEA54] border rounded-lg px-8 py-6">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-[#00000080]">Job Title</p>
                  <p className="text-xl text-black font-500">{props.jobPosition}</p>
                </div>

                <div>
                  <p className="text-[#00000080]">Current Deadline</p>
                  <p className="text-xl text-black font-500">{applicationDeadline}</p>
                </div>
              </div>
            </div>

            <div className="bg-[#FF6633] rounded-sm py-2 px-4">
              <p className="font-semibold leading-[1.4] text-white">Select new deadline</p>
            </div>

            <div className="flex bg-[#777DEA1A] border-[#777DEA54] border py-4 px-6 rounded-lg flex-col gap-y-4">
              <p className="font-500 text-sm leading-normal">How many days do you want to add to the deadline?</p>

              <div className="flex flex-col gap-y-3 items-center">
                {
                  ["7 days", "14 days", "21 days"].map((item, i) => {
                    return (
                      <label
                        key={i}

                        className={`flex items-center w-full px-4 py-2 rounded-md border ${selectedValue === item ? "border-[#FF6633]" : "border-[#898989]"
                          } cursor-pointer`}
                      >
                        <input
                          type="radio"
                          id={item.split(" ").join("_")}
                          name="new_deadline_mob"
                          value={item}
                          checked={selectedValue == item}
                          onChange={handleRadioChange}
                          className="w-6 h-6 text-[#FF6633] accent-[#FF6633] cursor-pointer"
                        />
                        <span className="ml-2 text-sm w-full font-medium text-gray-800">
                          {item}
                        </span>
                      </label>
                    )
                  })
                }
              </div>

              <div className="p-4 bg-[#F9D3C6] text-[#22263A] font-500 rounded-lg w-max">Your new deadline will be: <span className="text-xl">{`   ${formatDateToDDMMYYYY(updatedDeadline) || applicationDeadline}`}</span></div>
            </div>

            <div onClick={() => handleUpdateJob(props.custom_name, updatedDeadline)} className="cursor-pointer bg-[#FF6633] px-9 py-3 rounded-lg text-white w-max">Save New Deadline</div>
          </div>

        </section></div>}
      <ToastContainer />
    </>
  )
}